<template>
  <v-container class="px-0">
    <nav class="order__nav align-start pl-3">
      <h3 class="cart__title">{{ $t('REGISTRATION.my_order') }}</h3>
      <ul class="bill__nav pa-0">
        <li class="mr-2">
          <router-link :to="{ name: 'adming basket' }">
            Basket
          </router-link>
        </li>
        <li class="mr-2">
          <router-link :to="{ name: 'admin order registration' }">
            Registration
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'admin order complete' }">
            Order complete
          </router-link>
        </li>
      </ul>
    </nav>
    <transition name="slide-fade" mode="out-in">
      <router-view :key="$route.name" />
    </transition>
  </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.cart__title {
  text-align: left;
  text-transform: uppercase;
}
.bill__nav {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;

  li:not(:last-child) a {
    &::after {
      content: '';
      background-image: url('/img/icons/arrow-right-icon.svg');
      background-repeat: no-repeat;
      display: inline-block;
      width: 5px;
      height: 8px;
      margin-left: 7px;
    }
  }

  a {
    font-family: 'Alegreya Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #818181;
  }
}
</style>
